<template>
  <el-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="解读点"
            prop="unscramblePoint"
            :rules="[ { required: true, message: '解读点不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.unscramblePoint"
              placeholder="请输入解读点"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="法律"
            prop="lawCode"
            :rules="[ { required: true, message: '法律不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.lawCode"
              placeholder="请选择法律"
              @change="getDetailsOptions(1)"
            >
              <el-option
                v-for="(law,k) in lawsOption"
                :key="k"
                :label="law.LawName"
                :value="law.lawCode"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="章节"
          >
            <el-select
              v-model="form.lawDetailsId"
              placeholder="请选择章节"
            >
              <el-option
                v-for="(chapter,k) in chapterOption"
                :key="k"
                :label="chapter.labelName"
                :value="chapter.lawDetailsId"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="解读人"
          >
            <el-input
              v-model="form.unscrambleUser"
              placeholder="请输入解读人"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="解读时间">
            <el-date-picker
              v-model="form.unscrambleTime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="解读详情">
            <EditorVue
              style="border: 1px solid rgb(204, 204, 204);"
              :content.sync="form"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="text-center mt20">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'
import {
  saveOrUpdateLawUnscramble, GetLawUnscrambleById, GetLawOptions, GetDetailsOptions,
} from '@/api/knowledgeBase/laws.js'

export default {
  components: {
    EditorVue,
  },
  data() {
    return {
      loading: false,
      form: {
        content: '',
        unscrambleUser: '',
        unscramblePoint: '',
        unscrambleTime: '',
        lawDetailsId: undefined,
        unscrambleContent: '',
        lawCode: '',
        id: 0,
      },
      lawsOption: [],
      chapterOption: [],
      query: this.$route.query,
    }
  },
  created() {
    this.getLawOptions()
    if (this.query.id) {
      this.form.id = Number(this.query.id)
      this.getDetailById()
    }
  },
  mounted() {
    this.navActiveInit(1, '法律解读', '知识库管理')
  },
  destroyed() {
    this.navActiveInit(2, '法律解读', '知识库管理', '/lawUnscramble')
  },
  methods: {
    back() {
      this.$router.push({
        path: '/lawUnscramble',
      })
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getDetailsOptions(type) {
      GetDetailsOptions({ lawCode: this.form.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          if (type === 1) {
            this.form.lawDetailsId = undefined
          }
          this.chapterOption = resData.data
        }
      })
    },
    getDetailById() {
      GetLawUnscrambleById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
          this.form.content = this.form.unscrambleContent
          this.form.lawDetailsId= this.form.lawDetailsId ? this.form.lawDetailsId : undefined
          if (this.form.lawCode) {
            this.getDetailsOptions(2)
          }
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.unscrambleContent = this.form.content
          saveOrUpdateLawUnscramble(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
 .icon-btn{
   position: relative;
   top: 10px;
   font-size: 20px;
   cursor: pointer;
 }
</style>
